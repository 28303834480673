import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import NavbarComp from './components/Navbar/NavComp'

// import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from 'react';
// import { InteractionRequiredAuthError } from "@azure/msal-browser";
// import { loginRequest } from './authConfig';


function App() {

  // { msalInstance }: any


// const { instance } = useMsal();
// const isAuthenticated = useIsAuthenticated();


// const requestClaim = {
//  ...loginRequest,
//  loginHint: ""
// }


useEffect(() => {
//  if (!isAuthenticated) {
//    instance.ssoSilent(requestClaim)
//      .then((response) => {
//        instance.setActiveAccount(response.account);
//      })
//      .catch((error) => {
//        if (error instanceof InteractionRequiredAuthError) {
//          instance.loginRedirect(loginRequest);
//        }
//       });
//  }


});

 return (
  <div className="App">
    <NavbarComp />
  </div>
 );
}

export default App;