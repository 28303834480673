import { useEffect } from 'react';

function Timeline() {

 
  useEffect(() => { 

    window.location.href = 'https://mr--ea.wixsite.com/my-site/timeline';

  }, []);

  
  return (
    <>
    </>
  )
}

export default Timeline;