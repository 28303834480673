import { Container, Nav, Navbar } from 'react-bootstrap'
// import SignInButton from './SignInButton';
// import SignOutButton from './SignOutButton';

export const NavLinks = () => {

//  const isAuthenticated = useIsAuthenticated();

 return (
   <Container>
     {/* <Navbar.Brand href="/"><img className="site-logo" src={logo} alt="Marinel logo" /></Navbar.Brand> */}
     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
     <Navbar.Collapse id="responsive-navbar-nav">
       {/* {
         isAuthenticated && (
           
         )
       } */}
       <Nav className="me-auto">
           <Nav.Link className="nav-link scrollto" href="/">Home</Nav.Link>         
         </Nav>
       <Nav className="ml-auto">
       {/* {isAuthenticated ? <Nav.Link><SignOutButton /></Nav.Link> : <Nav.Link><SignInButton /></Nav.Link>} */}
       </Nav>
     </Navbar.Collapse>
   </Container>
 )
}

export default NavLinks;
