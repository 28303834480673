import { ClientEnquiry } from "../types/ClientEnquiry";
import { Student } from "../types/Student";

const domain = "https://aremusoftware.azurewebsites.net/api/";
// const domain = "http://localhost:7071/api";

const getStudentURL = `${domain}/GetStudent`;
const getStudentsURL = `${domain}/GetStudents`;
const postStudentURL = `${domain}/PostStudent`;
const deleteStudentURL = `${domain}/DeleteStudent`;
const sendReminderURL = `${domain}/YorubaReminderTrigger`;

const postEnquiryURL = `${domain}/PostEnquiry`;

export const fetchStudents = async () => {
    const response = await fetch(getStudentsURL)
            .then(response => response.json() as Promise<Student[]>)

    return response;
};

export const fetchStudentById = async (id:string) => {
    const response = await fetch(`${getStudentURL}/${id}`)
            .then(response => response.json() as Promise<Student>)

    return response;
};

export const sendReminderId = async (id:string) => {
    const response = await fetch(`${sendReminderURL}?id=${id}`)
            .then(response => response.json() as Promise<string>)

    return response;
};

export const postEnquiry = async (enquiry: ClientEnquiry) => {
    const response = await fetch(postEnquiryURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(enquiry)
    });

    return response;
};

export const postStudent = async (student:Student) => {
    const response = await fetch(postStudentURL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(student)
    });

    return response;
};

export const deleteStudent = async (studentId:string) => {

    const response = await fetch(`${deleteStudentURL}/${studentId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response;
};

export const fetchTimesOfDay = () => {
    const dow = ['16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];

    return dow;
}

export const fetchDaysOfTheWeek = () => {
    const dow = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    return dow;
}