import { ChangeEvent, useEffect, useState } from 'react'
import hero from '../img/hero-img.png';
import marinel from '../img/Marinel-1.png'
import c_Kitchen from '../img/Carribean Kitchen.png';
import asRequested from '../img/AsRequested.png';
import yoruba from '../img/YorubAA.png';
import { Button, Form } from 'react-bootstrap';
import { ClientEnquiry } from '../types/ClientEnquiry';
import { postEnquiry } from '../functions/fetchEntities';

function Home() {

  const [client, setClient] = useState({name: "", phoneNumber: "", subject: ""} as ClientEnquiry);
  const [validated, setValidated] = useState(false);
 
  useEffect(() => { 


  }, []);


  const handleSubmit = async (event:any) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        
      await postEnquiry(client);

      alert(`Thank you ${client.name}, We will be in contact soon`);

      client.name = "";
      client.phoneNumber = "";
      client.subject = "";

      setClient(client);

      window.location.reload();
    }

    setValidated(true);
  };

  function handleNameChange(event: ChangeEvent<HTMLInputElement>): void {
      const name = event.target.value;

      setClient({...client, name: name});
  }

  function handlePhoneNumberChange(event: ChangeEvent<HTMLInputElement>): void {
    const phoneNumber = event.target.value;

    setClient({...client, phoneNumber: phoneNumber});
  }

  function handleSubjectChange(event: ChangeEvent<HTMLTextAreaElement>): void {
    const subject = event.target.value;

    setClient({...client, subject: subject});
  }

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                <h1>Bring your business online</h1>
                <h2>Empowering Digital Transformation for Tomorrow's Marketplace</h2>
                {/* <div className="d-flex justify-content-center justify-content-lg-start">
                  <a href="#about" className="btn-get-started scrollto">Get Started</a>
                  <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
                </div> */}
              </div>
              <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                <img src={hero} className="img-fluid animated" alt="" />
              </div>
            </div>
          </div>
      </section>
      
      <main id="main">

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>About Us</h2>
            </div>

            <div className="row content">
              <div className="col-lg-6">
                <p>
                  Our journey began with a passion for innovation and a deep understanding of technology's potential. At Aremu Software Solution, we specialise in turning complex software challenges into simple, elegant solutions. Our team of expert engineers is dedicated to leveraging the latest technologies to drive efficiency, creativity, and growth for our clients. We believe in building not just software, but experiences that empower and inspire
                </p>
              </div>

              <div className="col-lg-6 pt-4 pt-lg-0">
                <p>
                  We understand that every client is unique, and so are their challenges. That's why we adopt a highly personalised approach, working closely with you to understand your specific goals and constraints. Our collaborative process ensures transparency and alignment at every stage, from initial concept to final delivery, guaranteeing solutions that are not only effective but also truly bespoke.
                </p>
              </div>
            </div>

          </div>
        </section>

        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Services</h2>
              <p>We offer comprehensive web development services, creating websites that are not only visually appealing but also optimized for performance and usability. Our expertise includes front-end and back-end development, ensuring a seamless, responsive, and engaging online presence for your business</p>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bx bxl-dribbble"></i></div>
                  <h4>Custom Software Development</h4>
                  <p>Creating bespoke software solutions tailored to client's specific needs. This includes web applications, desktop software, and complex systems</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-file"></i></div>
                  <h4>Cloud Computing Services</h4>
                  <p>Setting up and managing cloud-based solutions, including serverless architectures, on platforms like AWS, Azure, or Google Cloud.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-tachometer"></i></div>
                  <h4>API Development and Integration</h4>
                  <p>Developing application programming interfaces (APIs) for various services or integrating existing APIs into applications to enhance functionality.</p>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                <div className="icon-box">
                  <div className="icon"><i className="bx bx-layer"></i></div>
                  <h4>Project Management</h4>
                  <p>Managing client's software projects, ensuring seamless coordination from conception to completion. Our expertise guarantees that projects are delivered on time, within budget, and to the highest quality standards.</p>
                </div>
              </div>

            </div>

          </div>
        </section>

        {/* <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">

            <div className="row">
              <div className="col-lg-9 text-center text-lg-start">
                <h3>Call To Action</h3>
                <p> Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              </div>
              <div className="col-lg-3 cta-btn-container text-center">
                <a className="cta-btn align-middle" href="#">Call To Action</a>
              </div>
            </div>

          </div>
        </section> */}

        <section id="portfolio" className="team">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Portfolio</h2>
              <p>Our portfolio highlights not just our projects, but the successful collaborations we've had with our clients. Each entry in our portfolio is a story of partnership and progress, showing how we work with clients to understand their needs and deliver solutions that propel their businesses forward. These case studies illustrate our approach to problem-solving and client satisfaction.</p>
            </div>

            <div className="row">

              <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={marinel} className="img-fluid" alt="" />

                    </div>
                  <div className="member-info">
                    <h4>Marinel School Software</h4>
                    <span>Bespoke School Management Software</span>
                    <p>Marinel School, a prestigious educational institution in Ghana, sought our expertise to digitise their administration processes. The objective was to streamline operations, enhance communication, and improve overall efficiency through digital solutions.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={c_Kitchen} className="img-fluid" alt="" />

                    </div>
                  <div className="member-info">
                    <h4>Carribean Kitchen</h4>
                    <span>Menu Display and Ordering System</span>
                    <p>Caribbean Kitchen approached us with the exciting challenge of creating an online food ordering platform to showcase and deliver their exquisite Caribbean cuisine. Our goal was to develop a vibrant, user-friendly website that captures the essence of Caribbean culinary delights while offering a seamless ordering experience.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={asRequested} className="img-fluid" alt="" />

                    </div>
                  <div className="member-info">
                    <h4>As Requested</h4>
                    <span>Contemporary Concierge Services</span>
                    <p>We partnered with AsRequested to create a straightforward and effective online presence for their contemporary concierge services. Our goal was to develop an accessible and easy-to-use digital platform that reflects the practicality and convenience of their service offerings.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img src={yoruba} className="img-fluid" alt="" />

                    </div>
                  <div className="member-info">
                    <h4>Yoruba.exe</h4>
                    <span>Language tutoring service</span>
                    <p>We had the exciting opportunity to collaborate with Yoruba.exe, an innovative online platform dedicated to tutoring the Yoruba language. Our mission was to create an engaging, interactive, and user-friendly digital environment that facilitates effective language learning.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </section>

        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">

            <div className="section-title">
              <h2>Contact</h2>
              <p>Ready to start your next project with us? We’d love to hear from you! Get in touch with our team today to discuss your software development needs.</p>
            </div>

            <div className="row">

              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>Manchester, United Kingdom</p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>mr-ea@hotmail.co.uk</p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+447 429424660</p>
                  </div>

                  <iframe
                      title='Google Map'
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d188484.1528951712!2d-2.2426!3d53.4808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1600000000000"
                      style={{ border: 0, width: '100%', height: '290px' }}
                      allowFullScreen>
                  </iframe>


                </div>

              </div>

              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
           

                <Form className="php-email-form" noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control 
                            id="edit-name"
                            type="text" 
                            placeholder="Your Name" 
                            value={client.name} 
                            onChange={handleNameChange} 
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPhoneNumber">
                            <Form.Label>Your Phone Number</Form.Label>
                            <Form.Control 
                            id="edit-phone-number"
                            type="text" 
                            placeholder="Your Phone Number" 
                            value={client.phoneNumber} 
                            onChange={handlePhoneNumberChange} 
                            required
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formSubject">
                            <Form.Label>Subject</Form.Label>
                            <textarea id="edit-subject" className="form-control" rows={8} onChange={handleSubjectChange} />
                        </Form.Group>

                        <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                            Send
                        </Button>
                    
                </Form>
                    
              </div>
            </div>

          </div>
        </section>

      </main>

      <footer id="footer">
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright <strong><span>Aremu Software Solutions</span></strong>. All Rights Reserved
          </div>
        </div>
      </footer>
    </>
  )
}

export default Home;