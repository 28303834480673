import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import {
 BrowserRouter as Router,
 Routes,
 Route
} from "react-router-dom";
import { NavLinks } from './NavLinks';
import Home from '../Home';
import Timeline from '../Timeline';


export default class NavbarComp extends Component {


 render() {
   return (
     <Router>
       <div>
       <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
         <NavLinks />
       </Navbar>
       </div>
       <div>
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/Timeline" element={<Timeline />} />
       </Routes>
       </div>
     </Router>
   )
 }
}
