import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { msalConfig } from './authConfig';

// const pca = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
// Set active acccount on page load
// const accounts = pca.getAllAccounts();
// if (accounts.length > 0) {
//   pca.setActiveAccount(accounts[0]);
// }

// pca.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {

//     const account = event.payload.account;
//     pca.setActiveAccount(account);
//   }
// });


// pca.handleRedirectPromise().then((response) => {
//   // Check if user signed in 
//   const account = pca.getActiveAccount();
//   if(!account){
//     // redirect anonymous user to login page 
//     pca.loginRedirect();
//   }
// }).catch((error) => {
//   console.log('error', error);
// });


const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>
    <App />
    {/* msalInstance={pca}  */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();